<template>
    <v-container fluid class="mt-16">
      <v-flex xs12 sm6 offset-sm3 mt-13>
        <v-card
            class="mx-auto"
            outlined
            elevation="5"
        >
          <v-card-text>
            <v-row>
              <v-col cols="6" align="center" >
                <h2 class="title">РЕГИСТРАЦИЯ</h2>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" md="6" xl="6" class="mb-16" justify="center">
                <form class="signup form" @submit.prevent="signup" ref="signup">
                  <v-layout column>
                    <v-flex>
                      <v-text-field
                          name="enail"
                          prepend-icon="mdi-account"
                          label="Почта"
                          id="username"
                          v-model="username"
                          type="email"
                          required></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                          name="password"
                          label="Пароль"
                          prepend-icon="mdi-lock"
                          id="id_password"
                          v-model="password"
                          type="password"
                          required></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                          name="confirmPassword"
                          label="Пароль еще раз"
                          prepend-icon="mdi-lock"
                          v-model="confirmPassword"
                          id="id_confirmPassword"
                          type="password"
                          hint="Поле не заполнено"
                          required
                      ></v-text-field>
                    </v-flex>
                    <v-flex>
                      <v-checkbox
                          v-model="checkbox"
                          :error="checkboxError"
                          :error-messages="checkboxErrorMsg"
                      >
                        <template v-slot:label>
                          <div>
                            Я принимаю
                                <a
                                    target="_blank"
                                    href="/static/terms_of_use.pdf"
                                    @click.stop
                                >пользовательское соглашение</a>
                            и подтверждаю, что ознакомлен и согласен с
                                <a
                                    target="_blank"
                                    href="/static/privacy_policy.pdf"
                                    @click.stop
                                >политикой конфиденциальности.</a>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-flex>
                    <v-flex class="text-xs-center">
                      <v-btn color="success" type="submit" elevation="0">Регистрация</v-btn>
                    </v-flex>
                  </v-layout>
                </form>
              </v-col>
              <v-col cols="6" md="6" xl="6" class="hidden-sm-and-down">
                <v-img :src='require("@/assets/images/signup.png")' contain max-height="700" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-container>
</template>

<script>
import error from "@/constants/error";

export default {
  data () {
    return {
      showMessage: false,
      checkbox: false,
      checkboxError: false,
      checkboxErrorMsg: '',
      username: '',
      password: '',
      confirmPassword: '',
      message: '',
    }
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push("/")
    }
  },
  methods: {
    resetDataForm() {
      this.checkbox = false
      this.username = ''
      this.password = ''
      this.confirmPassword = ''
    },
    signup() {
      let username = this.username;
      let password = this.password;
      let confirmPassword = this.confirmPassword;
      let checkbox = this.checkbox

      this.$recaptcha("login").then((token) => {
        let captcha_response = token
        if (!checkbox) {
          this.$store.dispatch("showSnack", {text: 'Необходимо принять пользовательское соглашение', color: "error"})
          return
        }

        if (!captcha_response) {
          this.$store.dispatch("showSnack", {text: 'Каптча не заполнена', color: "error"})
          return
        }

        if (password !== confirmPassword) {
          this.$store.dispatch("showSnack", {text: 'Пароли не совпадают', color: "error"})
          return
        }

        this.$store
            .dispatch("signup", { username, password, checkbox, captcha_response })
            .then(() => this.$router.push("/"))
            .catch((err)=>{
              error.show_error(err.response.data.data)
              this.resetDataForm()
              this.$refs.signup.reset();
            });
      })
    }
  }
};
</script>
